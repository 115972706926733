import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit {
  @Input()
  form!: FormGroup;
  @Input()
  name!: string;
  @Input()
  readonly!: boolean;
  @Input()
  label!: string;
  @Input()
  type: string = 'text';
  @Input()
  submitted: boolean = false;
  @Input()
  isCurrency: boolean = false;
  @Input()
  isPercentage: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  formControlHasError(name: string): boolean | null {
    const control = this.getFormControl(name);

    return control.invalid && control.errors && (control.dirty || control.touched || this.submitted);
  }

  hasValue(name: string): boolean {
    const ctrl = this.getFormControl(name);

    return ctrl.value;
  }

}

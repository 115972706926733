import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgClickOutsideModule} from 'ng-click-outside2';
import {CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule} from 'ngx-currency';
import {NgxMaskModule} from 'ngx-mask';
import {NgProgressModule} from 'ngx-progressbar';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {AngebotComponent} from './angebot/angebot.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AusschreibungComponent} from './ausschreibung/ausschreibung.component';
import {HomeComponent} from './home/home.component';
import {InputErrorComponent} from './input-error/input-error.component';
import {InputUploadComponent} from './input-upload/input-upload.component';
import {InputComponent} from './input/input.component';
import {HttpErrorInterceptor} from './interceptor/http.interceptor';
import {KontaktComponent} from './kontakt/kontakt.component';
import {LandingComponent} from './landing/landing.component';
import {AusschreibungService} from './service/ausschreibung.service';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: 'Fr ',
  suffix: '',
  thousands: '\'',
  nullable: true,
  min: undefined,
  max: undefined,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    AusschreibungComponent,
    AngebotComponent,
    InputErrorComponent,
    InputComponent,
    KontaktComponent,
    InputUploadComponent,
  ],
  imports: [
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      debounceTime: 10,
      color: '#007aff',
      spinner: true,
    }),
    NgClickOutsideModule,
    NgProgressHttpModule,
    BrowserModule,
    //RouterModule.forRoot([]),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AusschreibungService, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {Angebot, Ausschreibung, DownloadableSupplierDocument, User} from '../model/ausschreibung';

declare var API: string;
declare var SUBMISSION: number;
declare var AUTHENTICATED: boolean;
declare var STADT_ZH_BASEURL: string;

@Injectable({
  providedIn: 'root',
})
export class AusschreibungService {

  private readonly url: string;
  private selected: Ausschreibung = new Ausschreibung();
  private sel$ = new BehaviorSubject<Ausschreibung>(this.selected);
  private signedin$ = new BehaviorSubject<boolean>(AUTHENTICATED);

  constructor(private http: HttpClient) {
    this.url = API + 'anbieter/ausschreibungen';
  }

  public getBaseUrl(): string {
    return STADT_ZH_BASEURL;
  }

  public getSubmissionFromParam(): number {
    return SUBMISSION;
  }

  public createAngebot(id: string): Observable<Ausschreibung> {
    return this.http.post<Ausschreibung>(this.url + '/' + id + '/angebot', {});
  }

  public select(ausschreibung: Ausschreibung) {
    this.selected = ausschreibung;
    this.sel$.next(ausschreibung);
  }

  public get(): Observable<Ausschreibung | undefined> {
    return this.sel$;
  }

  public find(id: string): Observable<Ausschreibung> {
    return this.http.get<Ausschreibung>(`${this.url}/${id}/detail`);
  }

  public findAll(): Observable<Ausschreibung[]> {
    return this.http.get<Ausschreibung[]>(this.url);
  }

  public patchAngebot(angebot: Angebot): void {
    this.selected.angebot = angebot;
    this.sel$.next(this.selected);
  }

  public put(angebot: Angebot): void {
    this.http.put<Angebot>(`${this.url}/${this.selected.id}/angebot`, angebot).subscribe(next => {
      this.selected.angebot = next;
      this.sel$.next(this.selected);

      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      });

      Toast.fire({
        icon: 'success',
        title: 'Ihre Änderungen wurden gespeichert.',
      });
    });
  }

  public remove(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}/angebot`);
  }

  public finalize(angebot: Angebot): Observable<Angebot> {
    return this.http.put<Angebot>(`${this.url}/${this.selected.id}/angebot/finalize`, angebot);
  }

  public isSignedIn(): Observable<boolean> {
    return this.signedin$;
  }

  public me(): Observable<User> {
    return this.http.get<User>(`${this.url}/me`);
  }

  public getFile(key: string): Observable<DownloadableSupplierDocument> {
    return this.http.get<DownloadableSupplierDocument>(`${this.url}/documents/${key}`);
  }

  public deleteFile(key: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/${this.selected.id}/documents/${key}`);
  }

  public upload(file: File, key: string): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this.url}/${this.selected.id}/documents/${key}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public download(key: string): Observable<Blob> {
    return this.http.get(this.getDownloadUrl(key), {
      responseType: 'blob',
    });
  }

  public getDownloadUrl(key: string): string {
    return `${this.url}/${this.selected.id}/documents/${key}/download`;
  }

  public getDownloadAusschreibungUrl(key: number): string {
    return `${this.url}/${this.selected.id}/documents/ausschreibung/${key}/download`
  }

  public downloadBeiblatt(id: string): Observable<Blob> {
    return this.http.get(`${this.url}/${id}/documents/beiblatt`, {
      responseType: 'blob',
    });
  }

  public checkAccountType(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/checkAccount`);
  }

}

import {HttpEvent, HttpEventType} from '@angular/common/http';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR,} from '@angular/forms';
import {saveAs} from 'file-saver';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {SupportedFiles} from '../model/ausschreibung';
import {AusschreibungService} from '../service/ausschreibung.service';

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html',
  styleUrls: ['./input-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputUploadComponent),
      multi: true,
    }
  ],
})
export class InputUploadComponent implements OnInit, ControlValueAccessor {

  @Input()
  key!: string;

  @Input()
  label!: string | undefined;

  @Input()
  readonly!: boolean;

  @Input()
  form!: FormGroup;

  @Input()
  control: FormControl | null = null;

  @Input()
  submitted?: boolean = undefined;

  @Input()
  supportedFiles?: SupportedFiles[] = undefined;

  isRequired: boolean = false;

  uploaded: Subject<boolean> = new Subject();
  filename?: string;
  progress: number = 0;
  message: string | undefined;
  accept: string = '';

  Toaster: any;

  onChange: any;
  onTouched: any;

  constructor(
    private ausschreibungService: AusschreibungService,
  ) {
    this.Toaster = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.uploaded.next(false);
  }

  ngOnInit(): void {
    if (null !== this.control && undefined !== this.submitted) {
      this.isRequired = true;
    }

    if (this.supportedFiles != null) {
        this.accept = this.supportedFiles.map(f => '.' + f.fileExtension).join(',');
    }

    this.ausschreibungService.getFile(this.key).subscribe(file => {
      if (file && file.filename && file.filename.length > 0) {

        this.uploaded.next(true);
        this.filename = this.escapeHtml(file.filename);

        this.control?.setValue(this.filename);
      }
    });
  }

  upload(event: any): void {

    const file: File = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > 1073741824) {
      Swal.fire({
        icon: 'error',
        title: 'Upload fehlgeschlagen',
        text: 'Die Datei ' + file.name + ' ist zu gross. Bitte reduzieren Sie die Dateigrösse.',
        showCloseButton: true,
      });
      return;
    }

    let mimeTypeHit = true;

    if (this.supportedFiles) {
      mimeTypeHit = false;

      for (const value of this.supportedFiles) {
        mimeTypeHit = file.name.endsWith("." + value.fileExtension) && value.mimeType === file.type;

        if (mimeTypeHit) {
          break;
        }
      }
    }

    if (!mimeTypeHit) {
      Swal.fire({
        icon: 'error',
        title: 'Upload fehlgeschlagen',
        text: 'Die Datei ' + file.name + ' ist fehlerhaft. Aktzeptierte Dateitypen sind: ' + this.supportedFiles?.map(x => x.fileExtension).join(", "),
        showCloseButton: true,
      });

      return;
    }

    this.ausschreibungService.upload(file, this.key)

      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            const t = event.total ? event.total : 0;
            this.progress = Math.round(event.loaded / t * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:
            this.uploaded.next(true);
            this.filename = this.escapeHtml(event.body.filename);

            this.Toaster.fire({
              icon: 'success',
              title: this.filename + ' wurde hochgeladen.',
            });

            this.control?.setValue(this.filename);
        }
      });
  }

  escapeHtml(text: string): string {
    const map: { [key: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, m => map[m]);
  }

  download(): void {
    this.ausschreibungService
      .download(this.key)
      .subscribe(blob => saveAs(blob, this.filename));
  }

  delete(): void {
    this.ausschreibungService.deleteFile(this.key).subscribe(next => {
      this.Toaster.fire({
        icon: 'success',
        title: this.filename + ' wurde gelöscht.',
      });

      this.uploaded.next(false);
      this.filename = undefined;

      this.control?.setValue(undefined);
    });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(obj: any): void {
  }

  hasError(): boolean | null {
    if (!this.control || undefined === this.submitted) {
      return null;
    }

    return this.control.invalid && this.control.errors && (this.control.dirty || this.control.touched || this.submitted);
  }
}

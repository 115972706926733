<ng-progress></ng-progress>
<main data-modal="main" class="main" style="padding-top: 0px;">
  <div class="layout_wrapper">
    <div class="header">
      <div class="mod_header mod_header--bar is_sticky_topbar">
        <div class="mod_header__topbar">
          <div class="mod_header__topbar-inner">
            <div class="top_actions">
              <div class="mod_metanav">
                <ul>
                  <li>
                    <a target="_blank" href="{{addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb.html')}}">
                      Hilfe zur Angebotseingabe</a></li>
                </ul>
              </div>
              <div id="myaccountaccesscontroller" data-searchfield="wrapper" class="actions var_with_myaccount">
                <div id="myaccountaccess"
                     class="mod_myaccountaccess"
                     data-init="myaccountaccess"
                     data-header="my-account-access">
                  <div id="myaccountaccess_elements_if_user_is_not_logged_in"
                       class="mod_tooltip var_my_account"
                       data-init="tooltip"
                       data-tooltip-options="{&quot;group&quot;:&quot;dashboardcard&quot;}">
                    <button class="mod_button var_small icon user var_label"
                            [ngClass]="signedInClass()"
                            (click)="showMenu($event)">
                      <span class="label">{{user}}</span>
                    </button>


                    <button class="mod_tooltip__bg " data-tooltip="bg"></button>
                    <div id="modaldialogiduniqueID"
                         data-tooltip="wrapper"
                         class="modal form formModal"
                         *ngIf="menuOpen"
                         (clickOutside)="hideMenu($event)"
                         tabindex="-1"
                         role="dialog"
                         aria-hidden="true"
                         style="">
                      <div class="modal-header">
                        <a class="close icon" data-dismiss="modal">Schliessen</a>
                      </div>
                      <div class="modal-body" data-tooltip="modal-body" style="">
                        <div id="dashboardmetanavigation" class="mod_dashboardmetanavigation">
                          <ul>
                            <li>
                              <a id="myaccountaccess_link_allServices"
                                 href="{{addBaseUrl('app/mkfewww/web/auth/settings')}}"
                                 class="icon services">
                                Benutzerdaten bearbeiten
                              </a>
                            </li>
                            <li>
                              <a id="myaccountaccess_link_about"
                                 href="{{addBaseUrl('meinkonto')}}"
                                 class="icon services">
                                &Uuml;ber MeinKonto
                              </a>
                            </li>

                          </ul>
                        </div>
                        <div class="action_wrapper">
                          <a id="myaccountaccess_link_login"
                             href="oauth2/logout"
                             class="mod_button var_full_width var_secondary var_large  var_label">
                            <span class="label">Abmelden</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <a (click)="toggleMobileMenu()" class="btn_burger">
                  <span class="text">Menü öffnen</span>
                  <div class="icon_burger">
                    <span class="icon_burger_bar"></span>
                    <span class="icon_burger_bar"></span>
                    <span class="icon_burger_bar"></span>
                  </div>
                </a>

              </div>
            </div>
          </div>
        </div>

        <div class="mod_header__logobar">
          <div class="mod_header__logobar-inner">
            <div class="mod_header__logobar-logo">
              <a href="#" class="mod_logo mod_logo--in-bar">
                <img src="assets/media/logo/logo_hochbau_2.svg"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="burger_dropdown is_opening" [ngClass]="isMobileMenuOpen()" data-header="burger-dropdown">
      <div class="burger_dropdown_wrapper">
        <h1 class="visuallyhidden">Mobile Menu</h1>

        <div class="actionsbar">


          <a (click)="toggleMobileMenu()"
             data-header="burger"
             class="btn_burger"
             [ngClass]="isMobileMenuOpen()"
             aria-expanded="false">
            <span class="text">Menü schliessen</span>
            <div class="icon_burger">
              <span class="icon_burger_bar"></span>
              <span class="icon_burger_bar"></span>
              <span class="icon_burger_bar"></span>
            </div>
          </a>
        </div>

        <div class="scrolling_wrapper">
          <h2 class="visuallyhidden">Navigation</h2>

          <div class="mod_multilevelnav"
               data-init="multilevelnav"
               data-header="multilevelnav"
               data-multilevelnav-url-pattern="{id}/jcr:content/multilevelnav.json"
               data-multilevelnav-options="{ &quot;i18n&quot;: {&quot;backLinkPrefix&quot;: &quot;Zurück zu &quot;, &quot;openPageText&quot;: &quot;Seite öffnen&quot;}}">
            <div class="mod_multilevelnav_wrapper">
              <div class="slides_wrapper" data-multilevelnav="slides-wrapper">
                <div class="slide var_active" data-multilevelnav="slide">


                  <ul class="no_back_link">

                    <li class="var_wrapping_node">
                      <a (click)="toggleMobileMenu()" routerLink="landing"><span>Startseite</span></a>

                      <ul *ngIf="signedIn">
                        <li class="var_has_subitems"
                            routerLink=""
                            (click)="toggleMobileMenu()"
                            routerLinkActive="var_active"
                            [routerLinkActiveOptions]="{ exact: true }">
                          <a>Meine Ausschreibungen</a>
                        </li>

                        <li class="var_has_subitems" *ngIf="id != '-1'" [routerLink]="['/ausschreibung', id]"
                            routerLinkActive="var_active"
                            (click)="toggleMobileMenu()"
                            [routerLinkActiveOptions]="{ exact: true }">
                          <a>{{selectedAusschreibungTitle}}</a>
                        </li>

                        <li class="var_has_subitems" *ngIf="angebot" [routerLink]="['/angebot/', id]"
                            routerLinkActive="var_active"
                            (click)="toggleMobileMenu()"
                            [routerLinkActiveOptions]="{ exact: true }">
                          <a>Ihr Angebot</a>
                        </li>

                        <li class="var_has_subitems" *ngIf="angebot">
                          <a target="_blank" [href]="addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb.html')">Hilfe zur Angebotseingabe</a>
                        </li>
                      </ul>
                    </li>
                  </ul>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="dvb-content layout_wrapper">
    <div class="mod_topnav" *ngIf="signedIn">
      <ul>
        <li routerLink="" routerLinkActive="var_active" [routerLinkActiveOptions]="{ exact: true }">
          <a>Meine Ausschreibungen</a>
        </li>
        <li *ngIf="id != '-1'" [routerLink]="['/ausschreibung', id]"
            routerLinkActive="var_active"
            [routerLinkActiveOptions]="{ exact: true }">
          <a>{{selectedAusschreibungTitle}}</a>
        </li>
        <li *ngIf="angebot" [routerLink]="['/angebot/', id]"
            routerLinkActive="var_active"
            [routerLinkActiveOptions]="{ exact: true }">
          <a>Ihr Angebot</a>
        </li>
      </ul>
    </div>
    <div class="layout_section">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div>
    <div *ngIf="isLandingPage()" class="mod_footernavigation">
      <div class="layout_wrapper">
        <div class="navigation_wrapper layout_columns var_four_columns">
          <div class="mod_accordion">

            <a href="#" class="trigger">
              <span class="trigger_title">Dienstabteilungen</span>
            </a>
            <div class="content is_hidden">
              <ul class="mod_footernavigationlinklist">
                <li><a href="https://www.stadt-zuerich.ch/content/hbd/de/index/bewilligungen_und_beratung/afb.html">Amt
                  für Baubewilligungen</a></li>
                <li><a href="https://www.stadt-zuerich.ch/content/hbd/de/index/hochbau/ahb.html">Amt für Hochbauten</a>
                </li>
                <li><a href="https://www.stadt-zuerich.ch/content/hbd/de/index/staedtebau/afs.html">Amt für
                  Städtebau</a>
                </li>
                <li><a href="https://www.stadt-zuerich.ch/content/hbd/de/index/immobilien-bewirtschaftung/immo.html">Immobilien
                  Stadt Zürich</a></li>
              </ul>
            </div>
          </div>


          <div class="mod_accordion">

            <a href="#" class="trigger">
              <span class="trigger_title">Kontakt</span>
            </a>

            <div class="content is_hidden" data-accordion="content">
              <div class="mod_footernavigationcontact styled_content">
                <div class="mod_contact">


                  <div class="mod_contactcard" itemscope="" itemtype="http://schema.org/Organization">
                    <div class="org" itemprop="name">Amt für Hochbauten</div>
                    <div class="adr" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
                      <div class="street_address" itemprop="streetAddress">Lindenhofstrasse 21</div>
                      <div class="street_address" itemprop="streetAddress">Amtshaus III</div>
                      <span class="postal_code" itemprop="postalCode">8001 </span><span class="locality"
                                                                                        itemprop="addressLocality">Zürich</span>
                    </div>
                    <div class="number"><span>Telefon </span> <span itemprop="telephone"> <a href="tel:+41444121111">+41 44 412 11 11</a></span>
                    </div>
                    <div class="po_box">
                      <div class="po_box_label">Postadresse:</div>
                      <div class="po_box_title">Postfach<br>8021 Zürich</div>
                    </div>
                  </div>


                </div>

              </div>
            </div>

          </div>

          <div class="mod_accordion">

            <a href="#" class="trigger">
              <span class="trigger_title">Anleitungen und Informationen</span>
            </a>
               <div class="content is_hidden">
                  <ul class="mod_footernavigationlinklist">
                    <li><a target="_blank" href="{{addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb.html')}}">Hilfe zur Angebotseingabe</a></li>
                    <li><a href="https://www.stadt-zuerich.ch/portal/de/index/mein-konto-redirect/mein-konto/hilfeseiten.html">Hilfeseiten rund um «Mein Konto»</a>
                    </li>
                    <li><a href="https://www.stadt-zuerich.ch/hbd/de/index.html">Über das Hochbaudepartement der Stadt Zürich</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="layout_wrapper">
      <footer class="mod_footer" data-init="sharebuttons">
        <div class="social_media">
          <a class="social_media__link" href="https://www.stadt-zuerich.ch/socialmedia">
            <svg class="social_media__icon">
              <use xlink:href="assets/media/sprite.svg#ico_social_media_icongroup"></use>
            </svg>
            Stadt Zürich auf Social Media
          </a>
        </div>
        <div class="links_wrapper">
          <div class="links">
            <span>&copy; {{year}} Stadt Zürich</span>
            <a href="{{addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb-rechtliche-hinweise.html')}}">Rechtliche Hinweise</a>
            <a href="{{addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb-impressum.html')}}">Impressum</a>
            <a href="{{addBaseUrl('hbd/de/index/hochbau/bauausschreibungen/ausschreibungsplattform-ahb-barrierefreiheit.html')}}">Barrierefreiheit</a>
          </div>
        </div>
      </footer>
    </div>
</main>

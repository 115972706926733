import {AfterContentChecked, ChangeDetectorRef, Component} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Ausschreibung} from './model/ausschreibung';
import {AusschreibungService} from './service/ausschreibung.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterContentChecked {
  year: number = new Date().getFullYear();
  selectedAusschreibungTitle: string = '';
  id: string = "-1";
  angebot: boolean = false;
  signedIn: boolean = false;
  user: string | undefined = 'Mein Konto';

  menuOpen: boolean = false;
  mobileMenuOpen: boolean = false;

  public selected: Observable<Ausschreibung | undefined>

  constructor(
    private ausschreibungService: AusschreibungService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
  ) {

    this.ausschreibungService.isSignedIn().subscribe(n => {
      this.signedIn = n;

      if (this.ausschreibungService.getSubmissionFromParam() && n) {
        this.router.navigate(['/ausschreibung/', this.ausschreibungService.getSubmissionFromParam()]);
      }

      if (n) {
        this.ausschreibungService.me().subscribe(n => this.user = n.fullname);
      }
    })

    this.selected = this.ausschreibungService.get();

    this.selected.subscribe(next => {
      if (!next) {
        return;
      }
      this.selectedAusschreibungTitle = next.objekt ? next.objekt : '';
      this.id = next.id ? next.id : "-1";
      this.angebot = next.angebot !== undefined;
    })

  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public isSelected(): boolean {
    return this.id !== "-1";
  }

  showMenu($event: any): void {
    $event.stopPropagation();
    this.menuOpen = true;
  }

  hideMenu(event: any): void {
    this.menuOpen = false;
  }

  isLandingPage(): boolean {
    return this.router.url == '/landing';
  }

  addBaseUrl = (path: string) => `${this.ausschreibungService.getBaseUrl()}/${path}`;
  signedInClass = () => this.signedIn ? '' : 'var_secondary';

  isMobileMenuOpen = () => this.mobileMenuOpen ? 'is_open' : '';
  toggleMobileMenu = () => this.mobileMenuOpen = !this.mobileMenuOpen;
}

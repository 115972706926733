<ng-template #uploadContainer>

  <div class="mod_formfileupload" [ngClass]="{'error' : hasError()}">
    <input type="file" id="upload_{{key}}"
           (change)="upload($event)"
           [readOnly]="readonly"
           [attr.disabled]="readonly ? '' : null"
           [attr.formControl]="control"
           name="files"
           accept="{{accept}}"
           class="file">
    <label for="upload_{{key}}">
      <span class="mod_button">
        <span class="label">Datei wählen</span>
      </span>
    </label>
    <p class="desc">{{label}} <span *ngIf="isRequired">*</span></p>
    <br *ngIf="null !== control && undefined !== submitted">
    <app-input-error *ngIf="null !== control && undefined !== submitted" [submitted]="submitted" [control]="control"></app-input-error>
  </div>
</ng-template>

<ng-container *ngIf="(uploaded | async) == true; else uploadContainer">
  <div class="mod_formfileupload">
    <input type="file"
           id="uploaded_{{key}}"
           name="filesu"
           [readonly]="true"
           [disabled]="true"
           accept="{{accept}}"
           class="file is_valid"
    />

    <label for="uploaded_{{key}}">
      <span class="input_label" (click)="download()">{{filename}}</span>
      <span class="mod_button" (click)="delete()">
			<span class="label">Durchsuchen</span>
		</span>
    </label>

    <p class="desc">Maximale Dateigrösse beträgt 1 GByte</p>
  </div>
</ng-container>

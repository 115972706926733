import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import Messages from '../interceptor/messages';
import {Ausschreibung, Status, VerfahrensartSTADTZH} from '../model/ausschreibung';
import {AusschreibungService} from '../service/ausschreibung.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  Toaster: any;

  ausschreibungen$: Observable<Ausschreibung[]> | undefined;

  constructor(private ausschreibungService: AusschreibungService, private router: Router) {
    this.load();
    this.Toaster = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    });
  }

  ngOnInit(): void {
  }

  load(): void {
    this.ausschreibungen$ = this.ausschreibungService.findAll();
  }

  open(id: string): void {
    this.router.navigate(['/ausschreibung', id]);
  }

  beautifyState(status: Status | undefined, einreichefrist: Date | undefined): string {

    const inFrist = einreichefrist ? new Date(einreichefrist) > new Date() : false;

    if (!inFrist) {
      return 'Ausschreibung beendet';
    }

    switch (status) {
      case Status.IN_BEARBEITUNG:
        return 'In Bearbeitung';
      case Status.BEENDET:
        return 'Abgesendet';
      default:
        return '';
    }
  }

  removeAusschreibung(id: string): void {
    Swal.fire({
      title: 'Wollen Sie dieses Angebot wirklich löschen?',
      text: 'Falls Sie das Angebot löschen, wird es unwiderruflich aus dem System gelöscht.',
      showCancelButton: true,
      confirmButtonText: 'Löschen',
      cancelButtonText: 'Abbrechen',
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ausschreibungService.remove(id).subscribe(next => {
          this.load();
        });

        this.Toaster.fire({
          icon: 'success',
          title: 'Sie haben das Angebot erfolgreich gelöscht.',
        });
      }
    });
  }

  downloadBeiblatt(id: string): void {
    this.ausschreibungService.downloadBeiblatt(id).subscribe(blob => saveAs(blob, 'Beiblatt.pdf'));
  }

  showHint(): void {
    Swal.fire({
      icon: 'error',
      title: Messages.submission_closed.title,
      text: Messages.submission_closed.body,
      showCloseButton: true,
    })
  }

  greyIcon(status: Status | undefined, frist: Date | undefined): boolean {

    if (!status || !frist) {
      return false;
    }

    return status === Status.BEENDET && new Date(frist) < new Date();

  }

  isFreihaendig = (verfahrensart: VerfahrensartSTADTZH | undefined) =>  verfahrensart === VerfahrensartSTADTZH.FREIHAENDIG;
}

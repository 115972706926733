<ng-container *ngIf="ausschreibung !== undefined">
  <div class="mod_pagetitle">
    <h1 class="page_title" id="anchorContent">Ausschreibung {{ausschreibung.objekt}}</h1>
  </div>

  <div class="parsys_column two_cols">
    <div class="parsys_column two_cols-c0">
      <p [innerHTML]="ausschreibung.title"></p>
      <p *ngIf="!isFreihaendig(ausschreibung?.verfahrensartSTADTZH)">Frist: {{ausschreibung.einreichefrist | date : 'dd.MM.yyyy, H:mm'}} Uhr</p>
      <a class="mod_button" *ngIf="!hasAngebot() && inFrist" (click)="createAngebot()"><span class="label">Angebot erstellen</span></a>
      <a class="mod_button button-grey" type="button" *ngIf="!hasAngebot() && !inFrist"><span class="label">Ausschreibung beendet</span></a>
      <a class="mod_button" *ngIf="hasAngebot()" (click)="toAngebot()"><span class="label">Mein Angebot</span></a>
    </div>
    <div class="parsys_column two_cols-c1">
      <h2 class="section_heading">Aktueller Stand des Angebots</h2>

      <ng-container *ngIf="hasAngebot(); else noOffer">
        <p *ngIf="isInBearbeitung(ausschreibung.angebot?.status) && inFrist">
          <span class="phase active-phase">In Bearbeitung</span>
        </p>
        <p *ngIf="isBeendet(ausschreibung.angebot?.status) && inFrist">
          <span class="phase active-phase">Abgesendet</span>
        </p>
        <p *ngIf="!inFrist">
          <span class="phase active-phase">Ausschreibung beendet</span>
        </p>
      </ng-container>

      <ng-template #noOffer>
        <p>Sie haben noch kein Angebot erstellt.</p>
      </ng-template>

      <ng-container *ngIf="ausschreibung.ausschreibungsDokumente && ausschreibung.ausschreibungsDokumente.length">
        <h2 class="section_heading">Dokumente zur Ausschreibung</h2>

        <div class="mod_downloadlink"
             *ngIf="ausschreibung.ausschreibungsDokumente && ausschreibung.ausschreibungsDokumente.length > 0; else noDocs">
          <ng-container *ngFor="let doc of ausschreibung.ausschreibungsDokumente">
            <a [href]=generateAusschreibungsDokumenDocumentUrl(doc.id) class="downloadlink icon_document" target="_blank">
              {{doc.name}}
            </a>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="ausschreibung.presentDocuments && ausschreibung.presentDocuments.length">
        <h2 class="section_heading">Ihre Angebotsdokumente</h2>
        <div class="mod_downloadlink"
             *ngIf="ausschreibung.presentDocuments && ausschreibung.presentDocuments.length > 0; else noDocs">
          <ng-container *ngFor="let doc of ausschreibung.presentDocuments">
            <a [href]="generateAusschreibungDocumentUrl(doc)" class="downloadlink icon_document" target="_blank">
              {{doc.filename}}
            </a>
          </ng-container>
        </div>
        <div *ngIf="ausschreibung?.angebot && ausschreibung?.angebot?.hash">
          Referenz-Nummer: {{ ausschreibung?.angebot?.hash }}
        </div>


      </ng-container>
    </div>
  </div>

</ng-container>

<ng-template #noDocs>
  <p>Keine Dokumente vorhanden.</p>
</ng-template>

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {saveAs} from 'file-saver';
import Swal from 'sweetalert2';
import {Angebot, Ausschreibung, DownloadableSupplierDocument, Status, VerfahrensartSTADTZH} from '../model/ausschreibung';
import {AusschreibungService} from '../service/ausschreibung.service';

declare var MEIN_KONTO_CONVERSION_TARGET: string;

@Component({
  selector: 'app-ausschreibung',
  templateUrl: './ausschreibung.component.html',
  styleUrls: ['./ausschreibung.component.css'],
})
export class AusschreibungComponent implements OnInit {
  Status = Status;
  ausschreibung: Ausschreibung | undefined;
  angebot: Angebot | undefined;
  inFrist: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ausschreibungService: AusschreibungService,
  ) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(x => {
      if (x['ausschreibung']) {
        this.ausschreibung = x['ausschreibung'];
        this.angebot = this.ausschreibung?.angebot;

        this.ausschreibungService.select(x['ausschreibung']);

        if (this.ausschreibung?.einreichefrist) {
          this.inFrist = new Date(this.ausschreibung?.einreichefrist) > new Date();
        }
      }
    });
  }

  createAngebot(): void {

    this.ausschreibungService.checkAccountType().subscribe(res => {
      if (res === false) {
        Swal.fire({
          title: 'Geschäftskonto',
          text: 'Um ein Angebot abzugeben müssen Sie über ein MeinKonto Geschäftskonto verfügen. Mit Klick auf OK werden Sie zu MeinKonto weitergeleitet.',
          confirmButtonText: 'OK',
        }).then((result) => {
          window.location.href = MEIN_KONTO_CONVERSION_TARGET;
        });
      } else {

        if (!this.ausschreibung) {
          console.error('ausschreibung not loaded');
          return;
        }

        this.ausschreibungService.createAngebot(this.ausschreibung.id).subscribe(next => {
          this.ausschreibungService.select(next);
          this.toAngebot();
        });
      }
    })
  }

  toAngebot(): void {
    this.router.navigate(['/angebot/', this.ausschreibung?.id]);
  }

  download(doc: DownloadableSupplierDocument): void {
    this.ausschreibungService
      .download(doc.key)
      .subscribe(blob => saveAs(blob, doc.filename));
  }

  generateAusschreibungDocumentUrl(doc: DownloadableSupplierDocument): string {
    return this.ausschreibungService.getDownloadUrl(doc.key);
  }

  generateAusschreibungsDokumenDocumentUrl(key: number): string {
    return this.ausschreibungService.getDownloadAusschreibungUrl(key);
  }

  hasAngebot = () => this.angebot !== undefined;
  isInBearbeitung = (status: Status | undefined) => status === Status.IN_BEARBEITUNG;
  isBeendet = (status: Status | undefined) => status === Status.BEENDET;
  isFreihaendig = (verfahrensart: VerfahrensartSTADTZH | undefined) =>  verfahrensart === VerfahrensartSTADTZH.FREIHAENDIG;
}

import {Component, OnInit} from '@angular/core';
import {AusschreibungService} from '../service/ausschreibung.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {

  constructor(private service: AusschreibungService) {
  }

  ngOnInit(): void {
  }

  addBaseUrl = (path: string) => `${this.service.getBaseUrl()}/${path}`;

}

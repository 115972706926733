<div class="mod_pagetitle">
  <h1 id="anchorContent" class="page_title">Kontakt
  </h1>
</div>

<div class="mainparsys parsys">
  <div class="mod_contact section">


    <div class="mod_contactcard" itemscope="" itemtype="http://schema.org/Organization">
      <div class="org" itemprop="name">Stadt Zürich</div>
      <div class="adr" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
        <div class="street_address" itemprop="streetAddress">Stadthausquai 17</div>
        <div class="street_address" itemprop="streetAddress">Stadthaus</div>
        <span class="postal_code" itemprop="postalCode">8001 </span><span class="locality" itemprop="addressLocality">Zürich</span>
      </div>
      <div class="number"><span>Telefon</span>
        <span itemprop="telephone"> <a href="tel:+41444121111">+41 44 412 11 11</a></span></div>
      <div class="po_box">
        <div class="po_box_label">Postadresse:</div>
        <div class="po_box_title">Postfach<br>8022 Zürich</div>
      </div>
    </div>


  </div>

</div>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Ausschreibung} from '../model/ausschreibung';
import {AusschreibungService} from '../service/ausschreibung.service';

@Injectable({
  providedIn: 'root',
})
export class AusschreibungResolver implements Resolve<Ausschreibung> {
  constructor(private ausschreibungService: AusschreibungService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Ausschreibung> {
    const id = route.paramMap.get('id');

    console.log('id is', id)
    // FIXME there might be a better way
    if (!id || id == 'undefined') {
      const loginCode = this.ausschreibungService.getSubmissionFromParam();

      if (loginCode) {
        this.router.navigate(['/ausschreibung/', this.ausschreibungService.getSubmissionFromParam()]);
      } else {
        this.router.navigate(['']);
      }
    }

    return this.ausschreibungService.find(id ?? "");

  }
}

const Messages = {

  offer_exists: {
    title: 'Angebot wurde bereits erstellt',
    body: 'Sie können nicht mehr als ein Angebot pro Ausschreibung erfassen.',
  },

  offer_not_exists: {
    title: 'Angebot existiert nicht',
    body: 'Das Angebot konnte nicht gefunden werden.',
  },

  offer_already_submitted: {
    title: 'Angebot bereits übermittelt',
    body: 'Angebot wurde bereits eingereicht und kann nicht mehr verändert werden.',
  },

  wrong_usertype: {
    title: 'Falscher Benutzertyp',
    body: 'Bitte benutzen Sie für die Ausschreibungen ein städtisches «Mein Konto» vom Typ Geschäftskunden-Konto.',
  },

  mail_error: {
    title: 'Fehler beim Versand des Bestätigungsmails',
    body: 'Das Bestätigungsmail konnte nicht versendet werden. Ihre Daten sind möglicherweise dennoch korrekt an die Stadt Zürich übermittelt worden. Bitte kontaktieren Sie uns für weitere Informationen.',
  },

  submission_not_yet_open: {
    title: 'Ausschreibung noch nicht öffentlich',
    body: 'Die Ausschreibung ist noch nicht verfügbar und startet erst zu einem späteren Zeitpunkt.',
  },

  submission_closed: {
    title: 'Frist ist abgelaufen',
    body: 'Die Frist ist abgelaufen und es können keine Angebote mehr eingegeben werden.',
  },

  submission_closed_no_offer: {
    title: 'Frist ist abgelaufen',
    body: 'Die Frist ist abgelaufen und es können keine Angebote mehr eingegeben werden.',
  },

  no_permission: {
    title: 'Keine Berechtigung!',
    body: 'Zugriff auf die Daten verweigert.',
  },

  hauptvariante_not_exists: {
    title: 'Keine Hauptvariante gefunden',
    body: 'Bitte überprüfen Sie Ihre Eingabe.',
  },

  file_upload_error: {
    title: 'Fehler beim Upload',
    body: 'Das Dokument konnte nicht hochgeladen werden. Bitte erneut versuchen.',
  },

  file_delete_error: {
    title: 'Fehler beim Löschen der Datei',
    body: 'Das Dokument konnte nicht gelöscht werden. Bitte erneut versuchen.',
  },

  file_download_error: {
    title: 'Fehler beim Download',
    body: 'Das Dokument konnte nicht heruntergeladen werden. Bitte erneut versuchen.',
  },

  submission_not_exists: {
    title: '',
    body: '',
  },

  beiblatt_error: {
    title: '',
    body: '',
  },

  document_not_found: {
    title: '',
    body: '',
  },

  first_not_declared: {
    title: '',
    body: '',
  },

}

export default Messages;

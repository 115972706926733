import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {
  Angebot,
  Requirement,
  SubUnternehmen,
  SupplierDocument,
  SupplierDocumentVariant,
  Variante,
} from '../model/ausschreibung';
import {AusschreibungService} from '../service/ausschreibung.service';

@Component({
  selector: 'app-angebot',
  templateUrl: './angebot.component.html',
  styleUrls: ['./angebot.component.css'],
})
export class AngebotComponent implements OnInit {

  subUnternehmen: FormGroup;
  arges: FormGroup;
  steps: number = 1;
  form: FormGroup;
  data$!: Observable<any>;

  requirementos?: Requirement[];
  supplierDocuments?: SupplierDocument[];
  readonly: boolean = true;
  finanzielleVariante?: Variante;
  id!: string;
  supplierId!: number;

  unternehmenSubmnitted: boolean = false;
  angebotSubmnitted: boolean = false;
  rechtlichesSubmnitted: boolean = false;
  additional: number[] = [];

  alleAnbieter: any[] = [];
  isSelektivesVerfahren = false;

  formControls = new Map<string, FormControl>();

  Toaster: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ausschreibungService: AusschreibungService,
  ) {
    this.Toaster = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    });
    this.subUnternehmen = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      plz: ['', Validators.required],
      ort: ['', Validators.required],
      bkpLeistung: ['', Validators.required],
    }),

      this.arges = this.fb.group({
        id: [null],
        name: ['', Validators.required],
        plz: ['', Validators.required],
        ort: ['', Validators.required],
        bkpLeistung: ['', Validators.required],
      }),

      this.form = this.fb.group({

        unternehmen: this.fb.group({
          name: ['', Validators.required],
          adresse: ['', Validators.required],
          plz: ['', Validators.required],
          ort: ['', Validators.required],
          uid: '',
          mwstNummer: '',
          kontaktpersonAnrede: ['', Validators.required],
          kontaktpersonVorname: ['', Validators.required],
          kontaktperson: ['', Validators.required],
          tel: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],

          arge: [false, Validators.required],
          argeName: '',
          subs: [false, Validators.required],
          subunternehmen: this.fb.array([]),
          arges: this.fb.array([]),
        }),

        angebot: this.fb.group({
          preisNettoInklMwstOfferte: [null, Validators.required],
          rabatt: null,
          skonto: null,
          qualifiedSignature: [null, Validators.required],

          finanzielleVariante: null,
          pauschalNettoInklMwst: null,
          additional1: null,
          additional2: null,
          additional3: null,
        }),

        requirements: this.fb.group({}),

      });

    this.form.get('finanzielleVariante')?.valueChanges.subscribe(value => {

        const field = this.getAngebotForm().get('pauschalNettoInklMwst');
        if (value) {
          field?.setValidators(Validators.required);
          field?.updateValueAndValidity();
        } else {
          field?.setValidators(null);
          field?.setValue(null);
          field?.updateValueAndValidity();
        }
      },
    );

    this.data$ = this.ausschreibungService.get();

    this.data$.subscribe(ausschreibung => {
      this.isSelektivesVerfahren = ausschreibung.verfahrensart === "selektiv";
      if (this.isSelektivesVerfahren) {
        this.form.get("angebot")?.get("preisNettoInklMwstOfferte")?.clearValidators();
      } else {
        this.form.get("angebot")?.get("preisNettoInklMwstOfferte")?.addValidators(Validators.required);
      }

      ausschreibung.angebot?.unternehmen?.subunternehmen.forEach((sub: SubUnternehmen) => {
        sub.name = this.unescapeWhitelistetChars(sub.name ?? "")
      });

      ausschreibung.angebot?.unternehmen?.arges.forEach((sub: SubUnternehmen) => {
        sub.name = this.unescapeWhitelistetChars(sub.name ?? "")
      });

      if (ausschreibung.angebot && ausschreibung.angebot.unternehmen) {
        ausschreibung.angebot.unternehmen.name = this.unescapeWhitelistetChars(ausschreibung.angebot.unternehmen.name);
        ausschreibung.angebot.unternehmen.argeName = this.unescapeWhitelistetChars(ausschreibung.angebot.unternehmen.argeName);
        ausschreibung.angebot.unternehmen.tel = this.unescapeWhitelistetChars(ausschreibung.angebot.unternehmen.tel);
      }

      if (!ausschreibung.angebot && this.router.url.includes('angebot')) {
        this.router.navigate(['/ausschreibung/', ausschreibung.id]);
        Swal.fire({
          icon: 'error',
          title: 'Angebot nicht vorhanden',
          text: 'Sie haben noch kein Angebot erstellt.',
          showCloseButton: true,
        })
      }

      this.id = ausschreibung.id;
      this.supplierId = ausschreibung.angebot.id;
      this.requirementos = ausschreibung.requirements;
      this.supplierDocuments = ausschreibung.supplierDocuments;
      this.finanzielleVariante = ausschreibung.finanzielleVariante;

      const date = new Date();
      const frist = new Date(ausschreibung.einreichefrist);

      this.readonly = (ausschreibung.angebot.readonly || frist <= date);

      if (ausschreibung.angebot.angebot.additional1) {
        this.additional = [1];
      }
      if (ausschreibung.angebot.angebot.additional2) {
        this.additional = [1, 2];
      }
      if (ausschreibung.angebot.angebot.additional3) {
        this.additional = [1, 2, 3];
      }
      this.setValidators();

      this.requirementos?.forEach((value, index) => {
        this.getRequirementForm().addControl('requirement_' + index, new FormControl(false, Validators.requiredTrue));
      });

      this.getSubUnternehmen().clear();
      this.getArges().clear();

      this.alleAnbieter = [];
      this.alleAnbieter.push({
        docIdentifier: 'HAUPT',
        name: ausschreibung.angebot?.unternehmen?.name,
      });

      ausschreibung.angebot?.unternehmen?.subunternehmen.forEach((sub: SubUnternehmen) => {
        this.onAddSubunternehmen();

        this.alleAnbieter.push({
          docIdentifier: sub.id,
          name: sub.name ?? "",
          id: sub.id,
        });
      });

      ausschreibung.angebot?.unternehmen?.arges.forEach((sub: SubUnternehmen) => {
        this.onAddArges();

        this.alleAnbieter.push({
          docIdentifier: sub.id,
          name: sub.name ?? "",
          id: sub.id,
        });
      });

      this.form.patchValue(ausschreibung.angebot);
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(x => {
      if (x['ausschreibung']) {
        this.ausschreibungService.select(x['ausschreibung']);
      }
    });
  }

  switchBack(step: number): void {
    this.steps = step;
    window.scrollTo(0, 0);
  }

  getLabel(variant: SupplierDocumentVariant): string|undefined {
    return variant?.supportedFiles?.map(x => x.fileExtension).join(", ");
  }

  /**
   * Diese Funktion niemals in einem HTML-Tag benutzen!
   * Folgende sind OK:
   * <p>{{unescapeWhitelistedChars(xxx)}}</p>
   *
   * Folgendes ist NICHT ok:
   * <input :placeholder="unescapedWhitelistedChars(xxx)" />
   *
   * @param toEscape string ter Escaped werden soll
   */
  unescapeWhitelistetChars(toEscape: string): string {
    return !toEscape ? '' : toEscape
      .replace("&#39;", "'")
      .replace("&amp;", "&")
      .replace("&#43;", "+")
      .replace("&#64;", "@");
  }

  switch(step: number): void {
    this.setSubmitted();
    const valid = this.isStepValid();

    if (!valid) {
      Swal.fire({
        icon: 'error',
        title: 'Fehler bei der Eingabe',
        text: 'Bitte überprüfen Sie Ihre Eingaben.',
        showCloseButton: true,
      });
      return;
    }

    if (this.isStepDirty() && valid && !this.readonly && step < 4) {
      const value = this.form.value as Angebot;
      this.ausschreibungService.put(value);
    }

    this.steps = step;
    window.scrollTo(0, 0);
  }

  setSubmitted(): void {
    switch (this.steps) {
      case 1:
        this.unternehmenSubmnitted = true;
        break;
      case 2:
        this.angebotSubmnitted = true;
        break;
      case 3:
        this.rechtlichesSubmnitted = true;
        break;
      default:
        break;
    }
  }

  isStepDirty(): boolean {
    switch (this.steps) {
      case 1:
        return this.getUnternehmenForm().dirty;
      case 2:
        return this.getAngebotForm().dirty;
      case 3:
        return this.getRequirementForm().dirty;
      default:
        return false;
    }
  }

  isStepValid(): boolean {
    switch (this.steps) {
      case 1:
        return this.getUnternehmenForm().valid;
      case 2:
        return this.getAngebotForm().valid;
      case 3:
        return this.getRequirementForm().valid;
      default:
        return false;
    }
  }

  getUnternehmenForm(): FormGroup {
    const unternehmen = this.form.get('unternehmen');

    if (!unternehmen) {
      console.error('form is missing');
    }

    return unternehmen as FormGroup;

  }

  getRequirementForm(): FormGroup {
    const requirements = this.form.get('requirements');

    if (!requirements) {
      console.error('form is missing');
    }

    return requirements as FormGroup;
  }

  getAngebotForm(): FormGroup {
    const angebot = this.form.get('angebot');

    if (!angebot) {
      console.error('form is missing');
    }

    return angebot as FormGroup;
  }

  getFormControl(key: string): FormControl {
    const angebot = this.form.get("angebot") as FormGroup;

    if(!angebot.contains(key)) {
      angebot.addControl(key, new FormControl(null, Validators.required));
    }

    return angebot.get(key) as FormControl;
  }

  getSubUnternehmen(): FormArray {
    return this.getUnternehmenForm().get('subunternehmen') as FormArray;
  }

  getArges(): FormArray {
    //debugger
    return this.getUnternehmenForm().get('arges') as FormArray;
  }

  hasSubunternehmen(): boolean {
    const unternehmen = this.getUnternehmenForm();
    const hasSubunternehmen = unternehmen.get('subs')?.value;

    if (hasSubunternehmen && 0 === this.getSubUnternehmen().length) {
      this.onAddSubunternehmen();
    }

    return hasSubunternehmen;
  }

  hasArges(): boolean {
    const unternehmen = this.getUnternehmenForm();
    const hasArges = unternehmen.get('arge')?.value;

    if (hasArges && 0 === this.getArges().length) {
      this.onAddArges();
    }

    return hasArges;
  }

  public argeValueChanged(): void {
    const arge = this.getUnternehmenForm().get('arge')?.value;
    const argeFormControl = this.getUnternehmenForm().get('argeName');

    if (arge) {
      argeFormControl?.setValidators(Validators.required);
      argeFormControl?.updateValueAndValidity();

      this.onAddArges();

      return;
    }

    this.getUnternehmenForm().get('argeName')?.removeValidators(Validators.required);
    argeFormControl?.updateValueAndValidity();

    for (let i = this.getArges().length - 1; i >= 0; i--) {
      this.onDeleteArges(i);
    }
  }

  public subUnternehmenChange(): void {
    const subs = this.getUnternehmenForm().get('subs')?.value;

    if (subs) {
      this.onAddSubunternehmen();

      return;
    }

    for (let i = this.getSubUnternehmen().length - 1; i >= 0; i--) {
      this.onDeleteSubunternehmen(i);
    }
  }

  public onAddSubunternehmen(): void {
    this.getSubUnternehmen().push(this.fb.group({
      id: '',
      name: ['', Validators.required],
      plz: ['', Validators.required],
      ort: ['', Validators.required],
      bkpLeistung: ['', Validators.required],
    }));
  }

  public onAddArges(): void {
    this.getArges().push(this.fb.group({
      id: '',
      name: ['', Validators.required],
      plz: ['', Validators.required],
      ort: ['', Validators.required],
      bkpLeistung: ['', Validators.required],
    }));
  }

  public onDeleteSubunternehmen(i: any) {
    this.getSubUnternehmen().removeAt(i);
    this.getSubUnternehmen().updateValueAndValidity();
  }

  public onDeleteArges(i: any) {
    this.getArges().removeAt(i);
    this.getArges().updateValueAndValidity();
  }

  public unternehmenValid(): boolean {
    return this.getUnternehmenForm().valid;
  }

  public getAllAnbieter(): any[] {
    let anbieter = [];
    anbieter.push({
      docIdentifier: 'HAUPT',
      name: this.getUnternehmenForm().get('name')?.value,
    });

    this.getSubUnternehmen().getRawValue().forEach(s => {
      anbieter.push({
        docIdentifier: s.id,
        name: s.name,
      });
    });

    this.getArges().getRawValue().forEach(s => {
      anbieter.push({
        docIdentifier: s.id,
        name: s.name,
      });
    });

    return anbieter;
  }

  public finanzielleVarianteAllowed(): boolean {
    return this.finanzielleVarianteSelected() && this.getAngebotForm().get('finanzielleVariante')?.value;
  }

  finalize() {

    this.setSubmitted();

    if (!this.isStepValid()) {
      Swal.fire({
        icon: 'error',
        title: 'Bitte Bedingungen akzeptieren',
        text: 'Um das Angebot einzureichen, müssen Sie alle Bedingungen akzeptieren.',
        showCloseButton: true,
      })
      return;
    }

    Swal.fire({
      title: 'Wollen Sie Ihr Angebot wirklich absenden?',
      text: 'Sobald Sie ihr Angebot abgesendet haben, können Sie keine Änderungen mehr vornehmen.',
      showCancelButton: true,
      confirmButtonText: 'Absenden',
      cancelButtonText: 'Zurück',
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const value = this.form.value as Angebot;

        this.ausschreibungService.finalize(value).subscribe(next => {
          this.finalizeSuccess();
        });
      }
    });
  }

  finalizeSuccess(): void {
    this.steps = 4;

    this.Toaster.fire({
      icon: 'success',
      title: 'Sie haben Ihr Angebot erfolgreich eingereicht.',
    });
  }

  getSubUnternehmenFormControl(name: string, index: number): FormControl {
    const arr = this.getUnternehmenForm().get('subunternehmen') as FormArray;
    return arr.at(index).get(name) as FormControl;

  }

  formControlHasError(control: FormControl): boolean | null {
    return control.invalid && control.errors && (control.dirty || control.touched || this.unternehmenSubmnitted);
  }

  toFormControl(control: any): FormControl {
    return control as FormControl;
  }

  cancel(): void {
    Swal.fire({
      title: 'Eingabe abbrechen',
      showCancelButton: true,
      text: 'Sie können den aktuellen Stand des Angebots speichern und später mit dem Ausfüllen fortfahren bis zum Erreichen der Eingabefrist.',
      confirmButtonText: 'Angebot verwerfen',
      cancelButtonText: 'Angebot speichern',
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.ausschreibungService.remove(this.id).subscribe(next => {
          this.Toaster.fire({
            icon: 'success',
            title: 'Ihr Angebot wurde erfolgreich gelöscht.',
          });
          this.router.navigate(['/']);
        })
      } else {
        const value = this.form.value as Angebot;
        this.ausschreibungService.put(value);
      }
    });
  }

  getNextLabel(): string {
    return this.readonly ? 'Weiter' : 'Weiter';
  }

  getRequirementControl(index: number): FormControl {
    return this.getRequirementForm().get('requirement_' + index) as FormControl;
  }

  requirementHasError(index: number): boolean | null {
    const control = this.getRequirementControl(index);

    return control.invalid && control.errors && (control.dirty || control.touched || this.rechtlichesSubmnitted);
  }

  finanzielleVarianteSelected(): boolean {
    if (!this.finanzielleVariante) {
      return false;
    }
    return this.finanzielleVariante !== Variante.KEINE;
  }

  getVarianteText(): string {
    switch (this.finanzielleVariante) {
      case Variante.GLOBAL:
        return 'Finanzielle Variante (Sie können ein zusätzliches Angebot global eintragen)'
      case Variante.PAUSCHAL:
        return 'Finanzielle Variante (Sie können ein zusätzliches Angebot pauschal eintragen)'
      default:
        return '';
    }
  }

  getVarianteAngebotLabel(): string {
    switch (this.finanzielleVariante) {
      case Variante.GLOBAL:
        return 'global';
      case Variante.PAUSCHAL:
      default:
        return 'pauschal';
    }
  }

  getAngebotDocumentIdentifier(): string {
    return `${this.id}_${this.supplierId}_ANGEBOT`;
  }

  showInfo(): void {
    Swal.fire({
      title: 'Unterzeichnung der Angebotsdokumente',
      text: 'Wählen Sie aus, ob Sie die Angebotsunterlagen elektronisch signieren möchten oder ob Sie eine handschriftlich unterzeichnete Angebotsbestätigung (Beiblatt) per Post senden möchten. \n' +
        'Wenn Sie elektronisch signieren, müssen alle zu unterzeichnenden Dokumente mit einer qualifizierten elektronischen Signatur (QES) nach Schweizer Recht versehen sein. Falls Sie eine QES beantragen möchten, finden Sie hier eine Liste der anerkannten Anbieterinnen von Zertifizierungsdiensten: https://www.bakom.admin.ch/bakom/de/home/digital-und-internet/digitale-kommunikation/elektronische-signatur.html. \n' +
        'Falls Sie das Beiblatt wählen, wird dieses im letzten Schritt generiert und kann anschliessend unterschrieben per Post eingereicht werden. Es muss spätestens am Werktag nach Ablauf der Angebotsfrist beim Amt für Hochbauten eintreffen.\n',
      confirmButtonText: 'OK',
      showCloseButton: true,
    });
  }

  addAdditional = () => {
    this.additional.push(this.additional.length + 1);
    this.setValidators();
  }

  removeAdditional(): void {
    const popped = this.additional.pop();

    if (!popped) {
      return;
    }

    const formValidator = this.getAngebotForm().get('additional' + popped);
    formValidator?.setValue(undefined);
    formValidator?.markAsDirty();

    this.ausschreibungService.getFile(this.getAdditionalIdentifier(popped)).subscribe(doc => {
      if (doc.filename !== "") {
        this.ausschreibungService.deleteFile(this.getAdditionalIdentifier(popped)).subscribe();
      }

      this.setValidators();
    });
  }

  getAdditionalName = (idx: number) => `additional${idx}`;
  getAdditionalIdentifier = (idx: number) => `${this.id}_${this.supplierId}_ADDITIONAL_${idx}`;

  hasBeiblatt = () => !this.getAngebotForm().get('qualifiedSignature')?.value;

  setValidators(): void {
    const one = this.getAngebotForm().get('additional1');
    one?.setValidators(null);
    const two = this.getAngebotForm().get('additional2');
    two?.setValidators(null);
    const three = this.getAngebotForm().get('additional3');
    three?.setValidators(null);
    if (this.additional.includes(1)) {
      one?.setValidators(Validators.required);
    }
    if (this.additional.includes(2)) {
      two?.setValidators(Validators.required);
    }
    if (this.additional.includes(3)) {
      three?.setValidators(Validators.required);
    }

    one?.updateValueAndValidity();
    two?.updateValueAndValidity();
    three?.updateValueAndValidity();
  }

  downloadBeiblatt(id: string): void {
    this.ausschreibungService.downloadBeiblatt(id).subscribe(blob => saveAs(blob, 'Beiblatt.pdf'));
  }
}

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import Swal from 'sweetalert2'
import Messages from './messages';

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            // @ts-ignore
            let msg = Messages[error.error.message];

            if (!msg) {
              msg = {
                title: 'Unerwarteter Fehler',
                body: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte erneut versuchen.',
              }
            }

            Swal.fire({
              icon: 'error',
              title: msg.title,
              text: msg.body,
              showCloseButton: true,
            });
          }
          console.log(error);
          return throwError(errorMsg);
        }),
      )
  }
}

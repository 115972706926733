export enum Status {
  IN_BEARBEITUNG = 'IN_BEARBEITUNG',
  BEENDET = 'BEENDET'
}

export enum VerfahrensartSTADTZH {
  OFFEN ='offen',
  EINLADUNG = 'einladung',
  SELEKTIV ='selektiv',
  SELEKTIV2 = 'selektiv2',
  FREIHAENDIG = 'freihaendig',
  NICHT_SVO = 'nichtSvo'
}

export enum Variante {
  KEINE = 'KEINE',
  PAUSCHAL = 'PAUSCHAL',
  GLOBAL = 'GLOBAL',
}

export class Angebot {
  id!: string;
  readonly?: boolean;
  status?: Status;
  unternehmen?: Unternehmen;
  angebot?: AngebotData;
  hash?: string;
  documentIdentifiers?: String[];
}

export class AngebotData {
  preisNettoInklMwstOfferte?: number;
  rabatt?: number;
  skonto?: number;
  qualifiedSignature?: boolean;

  finanzielleVariante: boolean = false;
  pauschalNettoInklMwst?: number;
  additional1?: string;
  additional2?: string;
  additional3?: string;
}

export class Unternehmen {
  name?: string;
  adresse?: string;
  plz?: string;
  ort?: string;
  uid?: string;
  mwstNummer?: string;
  arge: boolean = false;
  subs: boolean = false;

  kontaktpersonAnrede?: string;
  kontaktpersonVorname?: string;
  kontaktperson?: string;
  tel?: string;
  email?: string;
  anrede?: string;

  subunternehmen?: SubUnternehmen[];
  arges?: SubUnternehmen[];
}

export class SubUnternehmen {
  id!: number;
  name?: string;
  plz?: string;
  ort?: string;
  bkpLeistung?: string;
}

export class Ausschreibung {
  id!: string;
  objekt?: string;
  title?: string;
  bezeichnung?: string;
  einreichefrist?: Date;
  bauherrschaft?: string;
  projektbeziechnung?: string;
  auftrag?: string;
  bavNummer?: string;
  status?: Status;
  beiblatt?: boolean;
  uebermittlungsdatum?: Date
  documents?: Document[];
  angebot?: Angebot;
  ausschreibungsDokumente?: ArbeitsgattungsDokument[];

  presentDocuments?: DownloadableSupplierDocument[];
  finanzielleVariante?: Variante;
  supplierDocuments?: SupplierDocument[];
  requirements?: Requirement[];
  verfahrensart?: string;
  verfahrensartSTADTZH?: VerfahrensartSTADTZH;
}

export class ArbeitsgattungsDokument {
  id!: number;
  name?: string;
}

export class SupplierDocument {
  id!: number;
  alleAnbieter: boolean = false;
  title?: string;
  description?: string | null;
  variants?: SupplierDocumentVariant[];
}

export class SupportedFiles {
  fileExtension!: string;
  mimeType!: string;
}

export class SupplierDocumentVariant {
  id!: number;
  supportedFiles?: SupportedFiles[];
  required: boolean = false;
}

export class Requirement {
  text?: string;
}

export class Document {
  title?: string;
  type?: string;
  size?: string;
}

export class User {
  fullname?: string;
}

export class DownloadableSupplierDocument {
  filename?: string;
  key!: string;
}

<ng-container *ngIf="control.invalid && control.errors && (control.dirty || control.touched || submitted)">
  <span class="error_msg" *ngIf="control.hasError('required')">
    Bitte füllen Sie dieses Feld aus
  </span>
  <span class="error_msg" *ngIf="control.hasError('requiredTrue')">
    Bitte bestätigen Sie diese Bedingung
  </span>
  <span class="error_msg" *ngIf="control.hasError('email')">
    Bitte geben Sie eine gültige E-Mail Adresse an
  </span>
</ng-container>

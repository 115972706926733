<div class="mod_pagetitle">
  <h1 class="page_title" id="anchorContent">Angebotseingabe für Ausschreibungen</h1>
</div>
<div class="parsys_column">
  <div class="parsys_column two_cols-c0">
    <p>Auf der Ausschreibungsplattform des Amt für Hochbauten können Sie all Ihre Angebote erfassen und versenden. Um
      die Plattform nutzen zu können, benötigen Sie ein &laquo;Mein Konto&raquo; als Geschäftskunde. Die Registrierung dafür
      ist
      kostenlos.</p>
    <a href="oauth2/authorization/mein-konto-stadt-zh" class="mod_button icon user var_label">
      <span class="label">&Uuml;ber &laquo;Mein Konto&raquo; anmelden</span>
    </a>
    <div class="mod_styledtext">
      <p>Falls Sie noch kein &laquo;Mein Konto&raquo;-Login haben, können Sie sich
        <a href="{{addBaseUrl('appl/profil/registration/business?wesLoginUrl=https:%2F%2Fsubmissionen-hochbau.integ.stadt-zuerich.ch%2Flogin%2Foauth2%2Fcode%2F%3FRequestedPage%3D%26cmd%3Dcancel&requiredTrustLevel')}}">hier
          registrieren</a>.</p>
    </div>
  </div>

</div>

<div class="mod_formtextinput"
     [ngClass]="{'error' : formControlHasError(name), 'not_empty': hasValue(name)}"
     [formGroup]="form">
  <input *ngIf="isCurrency" [formControlName]="name"
         [type]="type"
         [readOnly]="readonly"
         [attr.disabled]="readonly ? '' : null"
         [name]="name"
         [id]="name"
         class="flying-focus_target"
         currencyMask/>

  <input *ngIf="isPercentage" [formControlName]="name"
         [type]="type"
         [readOnly]="readonly"
         [attr.disabled]="readonly ? '' : null"
         [name]="name"
         [id]="name"
         mask="0*"
         suffix=" %"
         class="flying-focus_target"/>

  <input *ngIf="!isCurrency && !isPercentage" [formControlName]="name"
         [type]="type"
         [attr.disabled]="readonly ? '' : null"
         [name]="name"
         [id]="name"
         class="flying-focus_target"/>
  <label [for]="name">{{label}}</label>
</div>
<app-input-error [submitted]="submitted" [control]="getFormControl(name)"></app-input-error>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AngebotComponent} from './angebot/angebot.component';
import {AusschreibungComponent} from './ausschreibung/ausschreibung.component';
import {HomeComponent} from './home/home.component';
import {KontaktComponent} from './kontakt/kontakt.component';
import {LandingComponent} from './landing/landing.component';
import {AusschreibungResolver} from './resolver/ausschreibung.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  }, {
    path: 'ausschreibung/:id',
    component: AusschreibungComponent,
    resolve: {ausschreibung: AusschreibungResolver},
  }, {
    path: 'angebot/:id',
    component: AngebotComponent,
    resolve: {ausschreibung: AusschreibungResolver},
  },
  {path: 'landing', component: LandingComponent},
  {path: 'ng', redirectTo: 'ausschreibung/undefined'},
  {path: 'kontakt', component: KontaktComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {

}

<div class="mod_formaem">
  <div class="mod_pagetitle">
    <h1 class="page_title" id="anchorContent">Ihr Angebot</h1>
  </div>

  <div class="progressbar-wrapper">
    <div class="mod_formaemprogressbar">
      <div class="progressbar">
        <div class="progress-object" [ngClass]="{'currentStep' : steps == 1}">
          <span class="numberBlock">1</span>
          <span class="labelBlock">Unternehmen</span>
        </div>
        <div class="progress-object" [ngClass]="{'currentStep' : steps == 2}">
          <span class="numberBlock">2</span>
          <span class="labelBlock">Dokumente</span>
        </div>
        <div class="progress-object" [ngClass]="{'currentStep' : steps == 3}">
          <span class="numberBlock">3</span>
          <span class="labelBlock">Abschluss</span>
        </div>
        <div class="progress-object" [ngClass]="{'currentStep' : steps == 4}">
          <span class="numberBlock">4</span>
          <span class="labelBlock">Bestätigung</span>
        </div>
      </div>

    </div>

  </div>
  <div class="form-wrapper">

    <form [formGroup]="form">

      <ng-container *ngIf="steps==1" formGroupName="unternehmen">

        <div class="border-bottom">

          <div>
            <label>Überprüfen Sie die Angaben zu dem Unternehmen und Ansprechpartner auf ihre Aktualität und
              korrigieren Sie diese falls notwendig.</label>
          </div>

          <div>
            <h2>Unternehmen</h2>
          </div>
          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()"
                       [submitted]="unternehmenSubmnitted"
                       name="name"
                       label="Unternehmen *"
                       [readonly]="readonly"></app-input>
          </div>
          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()" [submitted]="unternehmenSubmnitted"
                       name="adresse"
                       label="Strasse und Nr. *"
                       [readonly]="readonly" [attr.disabled]="readonly ? '' : null"></app-input>
          </div>
          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()"
                       name="plz"
                       label="PLZ *"
                       [submitted]="unternehmenSubmnitted"
                       [readonly]="readonly"
                       [attr.disabled]="readonly ? '' : null"></app-input>
          </div>
          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()"
                       name="ort"
                       label="Ort *"
                       [submitted]="unternehmenSubmnitted"
                       [readonly]="readonly"></app-input>
          </div>
          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()"
                       name="uid" [submitted]="unternehmenSubmnitted"
                       label="Unternehmens-Identifikationsnummer UID"
                       [readonly]="readonly"></app-input>
          </div>

          <div class="layout_column">
            <app-input [form]="getUnternehmenForm()"
                       name="mwstNummer" [submitted]="unternehmenSubmnitted"
                       label="MwSt.-Nummer"
                       [readonly]="readonly"></app-input>
          </div>





          <div class="mod_formcheckbox" data-init="formcheckbox">
            <input formControlName="arge" type="checkbox"
                   [readOnly]="readonly" [attr.disabled]="readonly ? '' : null"
                   id="arbeitsgemeinschaft"
                   name="arbeitsgemeinschaft" (change)="argeValueChanged()" />
            <label for="arbeitsgemeinschaft">Wir sind eine Arbeitsgemeinschaft (ARGE) </label>
          </div>

          <ng-container *ngIf="getUnternehmenForm().get('arge')?.value">
              <div class="margin-top-40">
                <label>Bitte geben Sie den Namen der Arbeitsgemeinschaft (ARGE) an</label>
              </div>

              <div class="form-group" class="mod_formtextinput not_empty"
                   [ngClass]="{'error' : formControlHasError(toFormControl(getUnternehmenForm().get('argeName')))}">
                <input formControlName="argeName" type="text"
                       name="argeName"
                       [readOnly]="readonly"
                       [attr.disabled]="readonly ? '' : null"
                       id="argeName"
                       class="flying-focus_target">
                <label for="argeName">Name der Arbeitsgemeinschaft (ARGE) *</label>
              </div>

            <div class="margin-top-40">
              <label>Bitte geben Sie die weiteren Unternehmen der ARGE an</label>
            </div>

            <ng-container formArrayName="arges"
                          *ngFor="let s of getArges()?.controls; let i=index">

              <ng-container [formGroupName]="i">

                <div class="form-group" class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('name')))}">
                  <input formControlName="name" type="text"
                         name="nameArge"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="nameArge"
                         class="flying-focus_target">
                  <label for="nameArge">Unternehmen *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('name'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('bkpLeistung')))}">
                  <input formControlName="bkpLeistung" type="text"
                         name="bkpLeistungArge"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="bkpLeistungArge"
                         class="flying-focus_target">
                  <label for="bkpLeistungArge">Leistung (BKP Nummer) *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('bkpLeistung'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('plz')))}">
                  <input formControlName="plz" type="text"
                         name="plzArge"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="plzArge"
                         class="flying-focus_target">
                  <label for="plzArge">PLZ *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('plz'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('plz')))}">
                  <input formControlName="ort" type="text"
                         name="ortArge"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="ortArge"
                         class="flying-focus_target">
                  <label for="ortArge">Ort *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('ort'))"></app-input-error>
                <div>
                  <input (click)="onDeleteArges(i)"
                         type="button"
                         [disabled]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         class="mod_button var_secondary margin-bottom-40"
                         value="Eintrag löschen">
                </div>
              </ng-container>
            </ng-container>
            <div>
              <input (click)="onAddArges()"
                     type="button"
                     [disabled]="readonly"
                     [attr.disabled]="readonly ? '' : null"
                     class="mod_button var_secondary"
                     value="Weiteren Eintrag hinzufügen"/>
            </div>
          </ng-container>






          <div class="mod_formcheckbox" data-init="formcheckbox">
            <input formControlName="subs"
                   [readOnly]="readonly" [attr.disabled]="readonly ? '' : null"
                   id="subUnternehmenCheckbox"
                   type="checkbox"
                   name="subUnternehmenCheckBox" (change)="subUnternehmenChange()"/>
            <label for="subUnternehmenCheckbox">Wir ziehen Subunternehmen bei</label>
          </div>

          <ng-container *ngIf="hasSubunternehmen()">
            <div class="margin-top-40">
              <label>Bitte geben Sie die einzelnen Subunternehmen an</label>
            </div>


            <ng-container formArrayName="subunternehmen"
                          *ngFor="let s of getSubUnternehmen()?.controls; let i=index">

              <ng-container [formGroupName]="i">

                <div class="form-group" class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('name')))}">
                  <input formControlName="name" type="text"
                         name="namesu"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="namesu"
                         class="flying-focus_target">
                  <label for="namesu">Unternehmen *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('name'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('bkpLeistung')))}">
                  <input formControlName="bkpLeistung" type="text"
                         name="bkpLeistung"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="bkpLeistung"
                         class="flying-focus_target">
                  <label for="bkpLeistung">Leistung (BKP Nummer) *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('bkpLeistung'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('plz')))}">
                  <input formControlName="plz" type="text"
                         name="plzSubunternehmen"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="plzSubunternehmen"
                         class="flying-focus_target">
                  <label for="plzSubunternehmen">PLZ *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('plz'))"></app-input-error>
                <div class="mod_formtextinput not_empty"
                     [ngClass]="{'error' : formControlHasError(toFormControl(s.get('plz')))}">
                  <input formControlName="ort" type="text"
                         name="ortSubunternehmen"
                         [readOnly]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         id="ortSubunternehmen"
                         class="flying-focus_target">
                  <label for="ortSubunternehmen">Ort *</label>
                </div>
                <app-input-error [submitted]="unternehmenSubmnitted"
                                 [control]="toFormControl(s.get('ort'))"></app-input-error>
                <div>
                  <input (click)="onDeleteSubunternehmen(i)"
                         type="button"
                         [disabled]="readonly"
                         [attr.disabled]="readonly ? '' : null"
                         class="mod_button var_secondary margin-bottom-40"
                         value="Eintrag löschen">
                </div>
              </ng-container>
            </ng-container>
            <div>
              <input (click)="onAddSubunternehmen()"
                     type="button"
                     [disabled]="readonly"
                     [attr.disabled]="readonly ? '' : null"
                     class="mod_button var_secondary"
                     value="Weiteren Eintrag hinzufügen"/>
            </div>
          </ng-container>


          <div>
            <h2>Ansprechpartner</h2>
          </div>

          <div class="form_section">
            <div class="mod_formradio var_horizontal">
              <input type="radio"
                     id="herr"
                     [disabled]="readonly" [attr.disabled]="readonly ? '' : null"
                     name="anrede"
                     formControlName="kontaktpersonAnrede"
                     value="Herr">
              <label for="herr">Herr</label>
            </div>

            <div class="mod_formradio var_horizontal">
              <input type="radio"
                     id="frau"
                     [disabled]="readonly" [attr.disabled]="readonly ? '' : null"
                     name="anrede"
                     formControlName="kontaktpersonAnrede"
                     value="Frau">
              <label for="frau">Frau</label>
            </div>
          </div>

          <div class="layout_column">

            <app-input [form]="getUnternehmenForm()" [submitted]="unternehmenSubmnitted"
                       name="kontaktperson"
                       label="Nachname *"
                       [readonly]="readonly"></app-input>

          </div>
          <div class="layout_column">

            <app-input [form]="getUnternehmenForm()" [submitted]="unternehmenSubmnitted"
                       name="kontaktpersonVorname"
                       label="Vorname *"
                       [readonly]="readonly"></app-input>
          </div>

          <div class="layout_column">

            <app-input [form]="getUnternehmenForm()" [submitted]="unternehmenSubmnitted"
                       name="tel"
                       label="Telefon *"
                       [readonly]="readonly"></app-input>
          </div>

          <div class="layout_column">
            <div class="flex-container">
              <div class="flex-input">
                <app-input [form]="getUnternehmenForm()" [submitted]="unternehmenSubmnitted"
                           name="email"
                           label="E-Mail *"
                           [readonly]="true"></app-input>
              </div>
              <div class="flex-edit">
                <a href="https://www.stadt-zuerich.ch/app/mkfewww/web/auth/settings"
                   title="Angaben bearbeiten"
                   class="icon_ico_pencil"></a>
              </div>
            </div>
          </div>

        </div>
        <div class="navigation-buttons">
          <div>
            <input *ngIf="!readonly"
                   type="button"
                   class="mod_button var_secondary"
                   (click)="cancel()"
                   value="Abbrechen">
          </div>

          <div>
            <input type="button"
                   (click)="switch(2)"
                   class="mod_button"
                   [value]="getNextLabel()">
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="steps==2" formGroupName="angebot">

        <div class="signature-info">
          <h2>Elektronische Signatur der Dokumente <span (click)="showInfo()" class="icon_ico_question"></span></h2>
          <div class="form_section" [class]="getFormControl('qualifiedSignature').errors?.['required'] ? 'error' : ''">
            <div class="mod_formradio">
              <input type="radio"
                     id="qualifiedSignatureTrue"
                     [disabled]="readonly"
                     [attr.disabled]="readonly ? '' : null"
                     name="qualifiedSignature"
                     formControlName="qualifiedSignature"
                     [value]="true">
              <label for="qualifiedSignatureTrue">
                Ich habe eine <strong>qualifizierte elektronische Signatur</strong> nach schweizerischem Recht, mit
                welcher ich alle zu unterzeichnenden Dokumente vor dem Hochladen digital signiert habe.
              </label>
            </div>

            <div class="mod_formradio">
              <input type="radio"
                     id="qualifiedSignatureFalse"
                     [disabled]="readonly"
                     [attr.disabled]="readonly ? '' : null"
                     name="qualifiedSignature"
                     formControlName="qualifiedSignature"
                     [value]="false">
              <label for="qualifiedSignatureFalse">
                Ich möchte bei der Übermittlung eine <strong>schriftliche Angebotsbestätigung (Beiblatt)</strong>
                generieren, welche ich ausdrucken, handschriftlich unterzeichnen und in Papierform einreichen kann.
                Die handschriftliche Unterzeichnung des Beiblatts gilt als Unterzeichnung für alle einzureichenden Unterlagen.
              </label>
            </div>
          </div>

          <div *ngIf="getFormControl('qualifiedSignature').invalid && (getFormControl('qualifiedSignature').dirty || getFormControl('qualifiedSignature').pristine)" class="alert">
            <div class="error_msg" *ngIf="getFormControl('qualifiedSignature').errors?.['required']">
              Bitte treffen Sie eine Auswahl.
            </div>
          </div>
        </div>

        <div class="border-bottom" *ngIf="!isSelektivesVerfahren">
          <div>
            <h2>Angebot</h2>
          </div>

          <div>
            <p>Füllen Sie bitte die Zahlen aus dem Angebot ein und laden Sie das Angebot hoch</p>
          </div>

          <div class="layout_column">

            <app-input-upload label="Angebot"
                              [key]="getAngebotDocumentIdentifier()"
                              [readonly]="readonly"
                              [control]="getFormControl('angebotDocument')"
                              [submitted]="angebotSubmnitted"
                              [form]="getAngebotForm()"
                              [supportedFiles]="[{'fileExtension': 'pdf', 'mimeType': 'application/pdf'}]"
            ></app-input-upload>
          </div>

          <div class="parsys">

            <div class="parsys_column two_cols flex-center">

              <div class="parsys_column two_cols-c0">
                <label>Eingabe netto inkl. MwSt. *</label>
              </div>
              <div class="parsys_column two_cols-c1">
                <app-input [form]="getAngebotForm()" [submitted]="angebotSubmnitted"
                           [isCurrency]="true"
                           name="preisNettoInklMwstOfferte"
                           [readonly]="readonly"></app-input>
              </div>
            </div>

            <div class="parsys_column two_cols flex-center">

              <div class="parsys_column two_cols-c0">
                <label>Rabatt %</label>
              </div>
              <div class="parsys_column two_cols-c1">
                <app-input [form]="getAngebotForm()" [submitted]="angebotSubmnitted"
                           name="rabatt"
                           [isPercentage] = true
                           [readonly]="readonly"></app-input>
              </div>
            </div>

            <div class="parsys_column two_cols flex-center">

              <div class="parsys_column two_cols-c0">
                <label>Skonto %</label>
              </div>
              <div class="parsys_column two_cols-c1">
                <app-input [form]="getAngebotForm()" [submitted]="angebotSubmnitted"
                           name="skonto"
                           [isPercentage] = true
                           [readonly]="readonly"></app-input>
              </div>
            </div>
          </div>

          <div class="mod_formcheckbox" *ngIf="finanzielleVarianteSelected()">
            <input formControlName="finanzielleVariante" type="checkbox"
                   id="finanzielleVariante"
                   [disabled]="readonly"
                   [attr.disabled]="readonly ? '' : null"
                   name="finanzielleVariante"/>
            <label for="finanzielleVariante">{{getVarianteText()}}</label>
          </div>

          <ng-container *ngIf="finanzielleVarianteAllowed()">
            <h2>Finanzielle Variante</h2>
            <div class="layout_column">

              <div class="parsys">

                <div class="parsys_column two_cols flex-center">

                  <div class="parsys_column two_cols-c0">
                    <label>Angebot {{getVarianteAngebotLabel()}} netto inkl. MwSt. *</label>
                  </div>
                  <div class="parsys_column two_cols-c1">
                    <app-input [form]="getAngebotForm()" [submitted]="angebotSubmnitted"
                               name="pauschalNettoInklMwst"
                               [isCurrency]="true" [readonly]="readonly"></app-input>

                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>


        <ng-container *ngFor="let sd of supplierDocuments;let i=index ">
          <div class="margin-top-40">
            <h2>{{sd.title}}</h2>
          </div>
          <p>{{sd.description}}</p>

          <div class="border-bottom" *ngIf="!sd.alleAnbieter">
            <ng-container *ngFor="let variant of sd.variants; let j=index">
              <div class="layout_column">
                <app-input-upload [label]="getLabel(variant)"
                                  [key]="id+'_'+supplierId+'_DEFINITION_'+sd.id +'_'+ 'HAUPT' + '_'+ variant.id"
                                  [readonly]="readonly"
                                  [control]="variant.required ? getFormControl(id+'_'+supplierId+'_DEFINITION_'+sd.id +'_'+ 'HAUPT' + '_'+ variant.id) : null"
                                  [submitted]="angebotSubmnitted"
                                  [formGroup]="getAngebotForm()"
                                  [supportedFiles]="variant.supportedFiles"
                ></app-input-upload>
              </div>
            </ng-container>
          </div>

          <div class="border-bottom" *ngIf="sd.alleAnbieter">

            <ng-container *ngFor="let anbieter of alleAnbieter; let x=index">
              <p class="margin-bottom-5">{{unescapeWhitelistetChars(anbieter.name)}}</p>
              <ng-container *ngFor="let variant of sd.variants; let y=index">

                <div class="layout_column">
                  <app-input-upload [label]="getLabel(variant)"
                                    [key]="id+'_'+supplierId+'_DEFINITION_'+sd.id +'_'+ anbieter.docIdentifier +'_'+ variant.id"
                                    [readonly]="readonly"
                                    [control]="variant.required ? getFormControl(id+'_'+supplierId+'_DEFINITION_'+sd.id +'_'+ anbieter.docIdentifier +'_'+ variant.id) : null"
                                    [submitted]="angebotSubmnitted"
                                    [formGroup]="getAngebotForm()"
                                    [supportedFiles]="variant.supportedFiles"
                  ></app-input-upload>
                </div>
              </ng-container>
            </ng-container>

          </div>

        </ng-container>

        <ng-container>

          <div>
            <h2>Weitere Dokumente</h2>
          </div>

          <div>
            <p>Es können bis zu drei weitere Dokumente hochgeladen werden.</p>
          </div>

          <div class="layout_column">

            <ng-container *ngFor="let addDoc of additional; let i = index">
              <app-input [form]="getAngebotForm()"
                         [submitted]="angebotSubmnitted"
                         [name]="getAdditionalName(addDoc)"
                         label="Name des Dokuments *"
                         [readonly]="readonly"></app-input>

              <app-input-upload label="Dokument"
                                [key]="getAdditionalIdentifier(addDoc)"
                                [readonly]="readonly"
                                [formGroup]="getAngebotForm()"
                                [supportedFiles]="[
                                    {'fileExtension': 'pdf' , 'mimeType': 'application/pdf'},
                                    {'fileExtension': 'doc' , 'mimeType': 'application/msword'},
                                    {'fileExtension': 'docx', 'mimeType': 'application/msword'},
                                    {'fileExtension': 'rtf' , 'mimeType': 'application/rtf'},
                                    {'fileExtension': 'xlsx', 'mimeType': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
                                    {'fileExtension': 'xls' , 'mimeType': 'application/vnd.ms-excel'},
                                    {'fileExtension': 'png' , 'mimeType': 'image/png'},
                                    {'fileExtension': 'jpg' , 'mimeType': 'image/jpeg'},
                                    {'fileExtension': 'jpeg', 'mimeType': 'image/jpeg'},
                                    {'fileExtension': 'ppt' , 'mimeType': 'application/vnd.ms-powerpoint'},
                                    {'fileExtension': 'pptx', 'mimeType': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'},
                                    {'fileExtension': 'crbx', 'mimeType': ''},
                                 ]"></app-input-upload>

              <div>
                <input (click)="removeAdditional()"
                       *ngIf="(additional.length - 1)=== i"
                       type="button"
                       [disabled]="readonly"
                       [attr.disabled]="readonly ? '' : null"
                       class="mod_button var_secondary margin-bottom-40"
                       value="Eintrag löschen"/>
              </div>
            </ng-container>

            <div>
              <input (click)="addAdditional()"
                     type="button"
                     [disabled]="readonly || this.additional.length >= 3"
                     [attr.disabled]="(readonly || this.additional.length >= 3) ? '' : null"
                     class="mod_button var_secondary"
                     value="Weiteres Dokument hinzufügen"/>
            </div>


          </div>

        </ng-container>


        <div class="navigation-buttons">

          <div>
            <input type="button" (click)="switchBack(1)" class="mod_button var_secondary" value="Zurück">
          </div>
          <div>
            <input type="button" (click)="cancel()" class="mod_button var_secondary" value="Abbrechen">
          </div>
          <div>
            <input type="button" (click)="switch(3)"
                   class="mod_button"
                   [value]="getNextLabel()">
          </div>
        </div>

      </ng-container>
      <ng-container *ngIf="steps==3" formGroupName="requirements">
        <div>
          <h2>Rechtliches</h2>
        </div>

        <p class="margin-bottom-40">Sie haben sich entschieden an der Ausschreibung teilzunehmen. Bitte lesen Sie sich
          die untenstehenden Bedingungen durch und akzeptieren Sie diese.</p>

        <ng-container *ngFor="let r of requirementos;let i=index ">
          <div class="mod_formcheckbox"
               [ngClass]="{'error' : requirementHasError(i)}">
            <input type="checkbox"
                   [disabled]="readonly"
                   [attr.disabled]="readonly ? '' : null"
                   formControlName="requirement_{{i}}"
                   id="requirement_{{i}}"
                   name="requirement_{{i}}"/>
            <label for="requirement_{{i}}" [innerHTML]="r.text">
            </label>
          </div>
          <app-input-error [control]="getRequirementControl(i)"></app-input-error>
        </ng-container>

        <div class="navigation-buttons margin-top-40">
          <div>
            <input type="button" (click)="switchBack(2)" class="mod_button var_secondary" value="Zurück">
          </div>
          <div>
            <input *ngIf="!readonly"
                   type="button"
                   (click)="finalize()"
                   class="mod_button"
                   value="Absenden">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="steps==4">
        <ng-container *ngIf="hasBeiblatt(); else noBeiblatt">

          <p>
            Vielen Dank für die Eingabe ihres Angebots. Bitte laden Sie Ihre Angebotsbestätigung (Beiblatt) herunter,
            drucken Sie diese aus und reichen Sie diese unterzeichnet dem Amt für Hochbauten ein.
          </p>

          <p>
            <button id="bestaetigungsButton" (click)="downloadBeiblatt(id)">Bestätigung herunterladen</button>
          </p>
          <p>
            Diese Bestätigung ist von Hand zu unterzeichnen und bis spätestens an dem auf den Ablauf der Angebotsfrist
            folgenden Werktag beim Amt für Hochbauten einzureichen. Das Datum des Poststempels ist nicht massgebend.
          </p>
          <p>
            Wir werden Ihre Eingabe zeitnah prüfen und Ihnen eine Rückmeldung geben.
          </p>
          <a routerLink="/">Zur Startseite</a>
        </ng-container>
      </ng-container>
    </form>
  </div>

</div>


<ng-template #noBeiblatt>
  <p>Vielen Dank für die Eingabe Ihrer Offerte. Wir werden diese zeitnah prüfen und Ihnen eine Rückmeldung zu
    Ihrem Angebot geben.</p>
  <a routerLink="/">Zur Startseite</a>
</ng-template>


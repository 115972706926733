<div class="mod_pagetitle">
  <h1 class="page_title" id="anchorContent">Angebotseingabe für Ausschreibungen der Stadt Zürich</h1>

  <div class="mod_styledtext">

    <p>Willkommen im Angebotseingabe-Tool für Ausschreibungen der Stadt Zürich. Hier finden Sie alle Ihre offenen
      Angebotseingaben zu Ausschreibungen bei der Stadt Zürich.</p>
    <p>Neue öffentliche Ausschreibungen finden Sie jeweils auf <a class="icon extern"
                                                                  href="https://www.simap.ch"
                                                                  target="_blank">Simap</a></p>
    <p>Bis zur Eingabefrist der Ausschreibung und solange das Angebot noch nicht versendet wurde, können Sie sich
      jederzeit
      wieder einloggen und Ergänzungen oder Korrekturen zu Ihrem Angebot vornehmen.</p>
    <p></p></div>
</div>
<div class="mod_table">
  <h2 class="table_title">Meine Ausschreibungen</h2>
  <div>
    <div class="table responsive-table">
      <ng-container *ngIf="((ausschreibungen$ | async)?.length ?? 0) > 0 ;else noOffers">
        <table>

          <thead>
          <tr>
            <th scope="col"><b>Objekt</b></th>
            <th scope="col"><b>Beschreibung</b></th>
            <th scope="col"><b>Einreichungsfrist</b></th>
            <th scope="col"><b>Status</b></th>
            <th scope="col"><b>Übermittlungsdatum</b></th>
            <th scope="col"><span class="visuallyhidden">Beiblatt</span></th>
            <th scope="col"><span class="visuallyhidden">Entfernen</span></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let ausschreibung of (ausschreibungen$ | async)">
            <tr class="point">
              <td (click)="open(ausschreibung.id)"><a>{{ausschreibung.objekt}}</a></td>
              <td (click)="open(ausschreibung.id)">{{ausschreibung.bezeichnung}}</td>
              <ng-container *ngIf="!isFreihaendig(ausschreibung?.verfahrensartSTADTZH); else freihaendigeCell">
                <td (click)="open(ausschreibung.id)"
                    *ngIf="ausschreibung.einreichefrist; else emptyCell">{{ausschreibung.einreichefrist | date : 'dd.MM.yyyy, H:mm'}}
                  Uhr
                </td>
              </ng-container>
              <ng-template #freihaendigeCell>
                <td (click)="open(ausschreibung.id)">-</td>
              </ng-template>
              <td (click)="open(ausschreibung.id)">{{beautifyState(ausschreibung.status,
                ausschreibung.einreichefrist)}}</td>
              <td (click)="open(ausschreibung.id)"
                  *ngIf="ausschreibung.uebermittlungsdatum; else emptyCell">{{ausschreibung.uebermittlungsdatum | date : 'dd.MM.yyyy, H:mm'}}
                Uhr
              </td>
              <td><a *ngIf="ausschreibung.beiblatt === true && ausschreibung.status === 'BEENDET'"
                     (click)="downloadBeiblatt(ausschreibung.id)"
                     title="Bestätigung drucken"
                     class="icon_ico_printer"></a></td>
              <ng-container *ngIf="!isFreihaendig(ausschreibung?.verfahrensartSTADTZH); else emptyCell">
                <td *ngIf="!greyIcon(ausschreibung.status, ausschreibung.einreichefrist)">
                  <a (click)="removeAusschreibung(ausschreibung.id)"
                     title="Angebot löschen"
                     class="icon_ico_trash2"></a></td>
                <td *ngIf="greyIcon(ausschreibung.status, ausschreibung.einreichefrist)"><a (click)="showHint()"
                     title="Angebot löschen"
                     class="icon_ico_trash2 icon-grey"></a>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noOffers>
  <p>Keine Ausschreibungen vorhanden.</p>
</ng-template>

<ng-template #emptyCell>
  <td></td>
</ng-template>
